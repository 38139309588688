import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Field, useField, useForm, useFormState } from 'react-final-form'
import { FormItemOnControl } from '../../molecules/FormItem'
import { Input } from '../../atoms/FormItems/Input'
import { checkPromocodes } from '../../api'
import { omit } from 'rambda'
import { size } from '../../constants'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const successMessage = 'Промокод успешно применён'

export const setFormStatePromocode = async (args, state, { changeValue }) => {
  const [promo] = args
  const { formState } = state

  if (promo) {
    const response = await checkPromocodes(promo)

    const reponseJson = await response.json()

    const isError = response.status >= 400
    formState.errors = !isError
      ? omit(['promo'], formState.errors)
      : { ...formState.errors, promo: true }

    const result = {
      status: response.status ?? reponseJson.status,
      message: response.message ?? reponseJson.message,
      id: reponseJson.id
    }

    changeValue(state, 'promocodeSubField', () =>
      getPromocodeSubfieldValue(result)
    )

    state.fields['promo'].focus()

    return response.status
  }
  formState.errors = omit(['promo'], formState.errors)
  changeValue(state, 'promocodeSubField', () => {})
}

export const getPromocodeSubfieldValue = (result) => ({
  status: result.status,
  message: result.message ?? successMessage,
  id: result.id
})

const getError = (promocodeSubField) =>
  promocodeSubField && promocodeSubField?.status !== 200
    ? promocodeSubField
    : null

export const usePromocode = () => {
  const [loading, setLoading] = useState(false)
  const {
    mutators: { setFormStatePromocode }
  } = useForm()
  const {
    input: { onChange: onChangeSubField }
  } = useField('promocodeSubField')
  const {
    values: { promocodeSubField, promo }
  } = useFormState()

  useEffect(() => {
    onChangeSubField()
    !promo && setFormStatePromocode()
  }, [promo])

  const submit = useCallback(async () => {
    setLoading(true)
    await setFormStatePromocode(promo)
    setLoading(false)
  }, [promo])

  return {
    currentError: getError(promocodeSubField),
    submit,
    loading,
    promocodeSubField
  }
}

const SendPromocodeButton = styled(
  ({ className, disabled, loading, onClick }) => (
    <Button.NewPrimary
      className={className}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      type="button"
    >
      Применить
    </Button.NewPrimary>
  )
)`
  min-width: 168px;
  height: 40px;

  @media (max-width: ${size.sm}) {
    height: 48px;
    width: fit-content;
  }
`

const validatorForSend = (promocodeSubField) => (value) =>
  value && promocodeSubField && promocodeSubField.status !== 200
    ? true
    : undefined

const PromocodeFormItem = styled(
  ({ className, currentError, promocodeSubField, loading, submit }) => (
    <Field name="promo" validate={validatorForSend(promocodeSubField)}>
      {({ input, meta }) => (
        <FormItemOnControl
          className={className}
          error={currentError}
          id="promo"
          label="Промокод"
          meta={meta}
          success={{
            visible: promocodeSubField?.status === 200,
            message: promocodeSubField?.message
          }}
        >
          <Row>
            <Input
              {...input}
              error={currentError}
              placeholder="Введите промокод"
              success={promocodeSubField?.status === 200}
            />
            <SendPromocodeButton
              disabled={!input.value}
              loading={loading}
              onClick={submit}
            />
          </Row>
        </FormItemOnControl>
      )}
    </Field>
  )
)`
  @media (max-width: ${size.sm}) {
    & > ${Row} > ${SendPromocodeButton} {
      display: none;
    }
  }
`

export const Promocode = styled(({ className }) => {
  const { input } = useField('promo', { value: true })
  const { currentError, submit, loading, promocodeSubField } = usePromocode({
    value: input.value
  })

  return (
    <div className={className}>
      <PromocodeFormItem
        currentError={currentError}
        loading={loading}
        promocodeSubField={promocodeSubField}
        submit={submit}
      />
      <SendPromocodeButton
        disabled={!input.value}
        loading={loading}
        onClick={submit}
      />
    </div>
  )
})`
  & ${SendPromocodeButton} {
    font-size: 14px;
    line-height: 20px;
  }

  & > ${SendPromocodeButton} {
    display: none;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > ${SendPromocodeButton} {
      display: flex;
    }
  }
`
